import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from "prismic-reactjs"
import Swiper from "../components/swiper"

const Heading = styled.h1`
  padding: 0 1rem;
  width: 100%;
`
const A = styled.a`
  text-decoration-color: #6594d4;

  h2 {
    padding: 0 1rem;
    color: #6594d4;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`
const Html = styled.div`
  padding: 0 1rem;
  a {
    color: black;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`
const Flex = styled.div`
  display: flex;
`
const FlexTwo = styled.div`
  display: flex;
  width: 66%;

  flex-direction: column;
  align-items: flex-end;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`
const Container = styled.div`
  margin-top: -8rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 800px) {
    margin-top: -16rem;
  }
`
const Header = styled.img`
  height: 500px;
  width: 100vw;
  object-fit: contain;
  @media only screen and (max-width: 800px) {
    transform: translateY(-5rem);
  }
`
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: row-reverse;
  scroll-behavior: smooth;
  p {
    word-break: normal;
    hyphens: auto;
    margin-bottom: 1.45em;
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`
const Info = styled.div`
  padding: 0 1rem 0 1rem;
  margin-bottom: 5.9rem;
  align-self: flex-end;

  color: #6594d4;
  width: 33%;
  background: transparent;
  h2 {
    font-size: 24px;
    padding-bottom: 0;
    background: transparent;
    margin-bottom: 1.2em;
    line-height: 26px;
  }
  h3:nth-of-type(2) {
    margin-bottom: 1.15em;
  }
  h3 {
    font-size: 24px;

    padding-bottom: 0;
    margin-bottom: 1.2em;
    line-height: 26px;
    border-bottom: none;
    a {
      color: #6594d4;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 1000px) {
    width: 100%;
    padding: 0;
  }
`

class Stipendium extends React.Component {
  render() {
    const data = this.props.data.sehgal.allSehgals.edges[0].node
    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Stipendium" />
        <Content>
          <FlexTwo>
            <Html>
              {RichText.render(data.title)}
              {RichText.render(data.description)}
            </Html>
          </FlexTwo>
          <Info>
            <Html>{data.info && RichText.render(data.info)}</Html>
          </Info>
        </Content>
      </Layout>
    )
  }
}
export default Stipendium

export const pageQuery = graphql`
  query SehgalQuery {
    sehgal: prismic {
      allSehgals(lang: "de-at") {
        edges {
          node {
            info
            description
            title
            _meta {
              id
            }
          }
        }
      }
    }
  }
`
